@import "../abstract/variables";
@import "../responsive/breakpoints";

.bottom__tab__wrapper {
  // position: fixed;
  // bottom: 0px;
  background: $bg-white;
  width: 425px;
  max-width: 100%;
  height: 50px;
  padding: 10px 0;
  .bottom__tab__lists {
    .tab__lists {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      align-items: center;
      .tab__list {
        .tab__menu {
          text-align: center;
          a {
            text-decoration: none;
            text-align: center;
            color: $text-disabled;
            transition: all 300ms ease-in-out;
            p {
              svg {
                font-size: 25px;
                margin-bottom: -8px;
              }
            }
            span {
              font-size: 14px;
            }
          }
          a.active {
            color: black;
            // color: $tb-text-dark-color;
          }
        }
      }
    }
  }
}
