@import "../../abstract/variables";
@import "../../responsive/breakpoints";

.tb__dashboard {
  .tb__dashboard__container {
    .tb__user__info {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-body;
      // position: sticky;
      top: 90px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 10px 0;
      height: 50px;
      width: 100%;
      max-width: 425px;
      z-index: 9999;
      h2 {
        text-align: center;
        font-size: 20px;
        color: $text-black;
        font-weight: 500 !important;
      }
    }
    .tb__dashboardHome__info {
      padding: 10px;
      margin-top: 20px;
      @include breakpoints(mobile) {
        padding: 10px 5px;
      }
      .tb__userDashboard__stats {
        margin-bottom: 40px;
        .tb__userDashboard__team {
          display: flex;
          justify-content: space-between;
          .totalTeam {
            h4 {
              display: flex;
              font-size: 22px;
              gap: 5px;
            }
          }
        }
        h4 {
          font-size: 25px;
          font-weight: bold;
          color: $tb-text-dark-color;
        }
        .tb__stats__cards {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #d2122e;
          // background: $tb-text-dark-color;
          position: relative;
          padding: 35px 25px;
          border-radius: 20px;
          margin-top: 10px;
          @include breakpoints(mdSmMobile) {
            padding: 30px 18px;
          }
          .tb__stats__mainBalance {
            color: $text-white;
            n h4 {
              color: $text-white;
              font-size: 20px;
              font-weight: 500 !important;
              opacity: 0.9;
              @include breakpoints(mdSmMobile) {
                font-size: 18px;
              }
            }
            span {
              color: $text-white;
              font-size: 16px;
              opacity: 0.9;
              @include breakpoints(mdSmMobile) {
                font-size: 15px;
              }
            }
            .requiredTeam {
              display: flex;
              justify-content: space-between;
              p {
                font-size: 18px;
                h4 {
                  font-size: 15px;
                }
              }
            }
          }
          .tb__vertical__war {
            position: absolute;
            margin-left: 5px;
            background: $bg-white;
            opacity: 0.5;
            left: 50%;
            right: 50%;
            width: 1px;
            height: 40%;
          }
        }
        .target__cards {
          background: transparent;
          padding: 0px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 5px;
          .tb__stats__mainBalance {
            // background: #d2122e;
            background: $bg-body;
            color: $text-black !important;
            padding: 30px 25px;
            border-radius: 20px;
            box-shadow: 0px 1px 0px 0px #e9e9e9;
            @include breakpoints(mdSmMobile) {
              padding: 30px 16px;
            }
            h4 {
              font-size: 15px;
              color: $text-black;
            }
            span {
              color: $text-black;
            }
          }
        }
      }
    }
  }
}
